import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import NoMatch from '../404Old';
import { QuizTemplate } from './graphql/Index_v2';

const Quiz: React.FC = () => {
  return (
    <Switch>
      <Redirect from="/quiz/17mai-2021" to="/quiz/17mai" />
      <Route exact path="/quiz/:urltag" component={QuizTemplate} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Quiz;
